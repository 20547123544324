import React from "react";
import "./Loader.scss";

export function Loader({ full }) {
  return (
    <div className={`loader${full ? " full" : ""}`}>
      <div className="spinner"></div>
    </div>
  );
}
