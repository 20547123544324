import React, { useEffect, useState } from "react";
import "./Input.scss";
import {
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineX,
  HiSearch,
} from "react-icons/hi";
import { ModalSmall } from "../Modal/Modals";
import { Button } from "../Button/Buttons";
import { FlexBox } from "../FlexBox/FlexBox";
import { useTranslation } from "react-i18next";

export function Input({
  type,
  placeholder,
  label,
  id,
  name,
  value,
  required,
  handleChange,
  valid,
  disabled,
  search,
  disabledSearchIcon,
  disabledSearchButton,
  extendedClass,
  isChecked,
}) {
  const [focused, setFocused] = useState(false);
  //INPUT PASSWORD
  const [visible, setVisibility] = useState(false);
  const inputType = type === "password" && visible ? "passwordNone" : type;
  const { t } = useTranslation();

  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <>
      <div
        className={`input${valid ? " valid" : ""}${
          focused === true ? " focused" : ""
        }${value && value.length > 0 ? " filled" : ""}${
          search ? " search" : ""
        }${type === "password" ? " password" : ""} ${
          extendedClass ? ` ${extendedClass}` : ""
        }`}
      >
        <label
          htmlFor={id}
          onFocus={handleFocus}
          className={isChecked ? "checked" : ""}
        >
          {label ? t(`${label}`) : ""}
        </label>
        {search && (
          <button className="searchIcon" disabled={disabledSearchIcon}>
            <HiSearch />
          </button>
        )}
        <input
          onChange={handleChange}
          id={id}
          type={inputType}
          placeholder={placeholder}
          value={value}
          name={name}
          required={required}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
        />
        {search && (
          <button className="searchButton" disabled={disabledSearchButton}>
            <HiSearch />
          </button>
        )}
        {type === "password" && "passwordNone" && (
          <button
            className="buttonTogglePassword"
            type="button"
            name="Toggle password visibilitry button"
          >
            {visible ? (
              <HiOutlineEye
                color="#ffffff"
                onClick={() => setVisibility((visibility) => !visibility)}
              />
            ) : (
              <HiOutlineEyeOff
                color="#777777"
                onClick={() => setVisibility((visibility) => !visibility)}
              />
            )}
          </button>
        )}
      </div>
    </>
  );
}
export function Textarea({
  type,
  placeholder,
  label,
  id,
  value,
  required,
  handleChange,
  valid,
  filled,
  withButton,
  button,
}) {
  const [focused, setFocused] = useState(false);
  const { t } = useTranslation();
  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <>
      <div
        className={`input${valid ? " valid" : ""}${
          focused === true ? " focused" : ""
        }${value && value.length > 0 ? " filled" : ""}`}
      >
        <label htmlFor={id} onFocus={handleFocus}>
          {t(`${label}`)}
        </label>
        <textarea
          onChange={handleChange}
          id={id}
          type={type}
          placeholder={placeholder}
          value={value}
          required={required}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    </>
  );
}
export function Checkbox({
  show,
  title,
  displayCheckbox,
  id,
  checked,
  button,
  buttonOnClick,
  handleChange,
  handleRemove,
  disabled,
  label,
}) {
  return (
    <div
      className={`checkbox${displayCheckbox ? " displayCheckbox" : ""}${
        show ? " show" : ""
      }${checked ? " checked" : ""}`}
    >
      <label htmlFor={id} className={`${checked ? " checked" : ""}`}>
        <FlexBox align="start">
          <input
            type="checkbox"
            checked={checked}
            id={id}
            onChange={handleChange}
            disabled={disabled}
          />
          <span>{label ? label : ""}</span>
        </FlexBox>
      </label>
    </div>
  );
}
export function CheckboxFilters({
  show,
  title,
  displayCheckbox,
  id,
  name,
  // checked,
  button,
  handleChange,
  handleRemove,
}) {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    handleChange(title, isChecked);
  };

  return (
    <div
      className={`checkbox${displayCheckbox ? " displayCheckbox" : ""}${
        show ? " show" : ""
      }${checked ? " checked" : ""}`}
    >
      <label htmlFor={id} className={`${checked ? " checked" : ""}`}>
        <input
          type="checkbox"
          checked={checked}
          id={id}
          // onChange={handleChange}
          onChange={handleCheckboxChange}
          name={name}
        />
        <span>{title}</span>
        {button && (
          <button onClick={handleChange}>
            <HiOutlineX onClick={handleRemove} />
          </button>
        )}
      </label>
    </div>
  );
}
export function Radio({
  show,
  displayCheckbox,
  id,
  checked,
  handleChange,
  disabled,
  label,
  name,
  row,
}) {
  return (
    <div
      className={`checkbox${displayCheckbox ? " displayCheckbox" : ""}${
        show ? " show" : ""
      }${checked ? " checked" : ""}${row ? " row" : ""}`}
    >
      <FlexBox align="center">
        <input
          type="radio"
          checked={checked}
          id={id}
          name={name}
          onChange={handleChange}
          disabled={disabled}
        />
        <label htmlFor={id} className={`${checked ? " checked" : ""}`}>
          {label}
        </label>
      </FlexBox>
    </div>
  );
}
export function InputSelect({
  options,
  value,
  handleChange,
  selectorTitle,
  selectorOptionsTitle,
}) {
  const [hideModalSmall, setHideModalSmall] = useState(true);
  const toggleModalSmall = () => setHideModalSmall(!hideModalSmall);
  const configModal = {
    hideModalSmall,
    toggleModalSmall,
  };

  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (value && selectedValue !== value) {
      setSelectedValue(value);
    }
  }, [value, selectedValue]);

  const handleOptionClick = (value) => {
    setSelectedValue(value);
    handleChange(value);
    toggleModalSmall();
    console.log(`${selectorTitle}: ${selectedValue}`);
  };

  return (
    <>
      <div>
        <Button
          type="button"
          title={
            <FlexBox justify="space">
              {selectorTitle}{" "}
              <span className={selectedValue ? "textBlack" : "textGrey"}>
                {selectedValue || "Choose"}
              </span>
            </FlexBox>
          }
          hierarchy="select"
          handleClick={() => toggleModalSmall()}
        />
      </div>
      <ModalSmall {...configModal}>
        <br />
        <br />
        <h2>{selectorOptionsTitle}</h2>
        <br />
        {options &&
          options.map((option, index) => (
            <Button
              title={
                <>
                  <h3>{option.value}</h3>
                  <br />
                  <small>{option.label}</small>
                </>
              }
              hierarchy="list"
              type="button"
              key={index}
              handleClick={() => handleOptionClick(option.value)}
              value={value}
            />
          ))}
      </ModalSmall>
    </>
  );
}
