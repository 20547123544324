import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkUserIsAdmin, checkUserLoverAccount } from "./userRoles";
import { createSelector } from "reselect";

const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

function useNoAuth(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/explore");
    }
  }, [currentUser, navigate]);

  return currentUser;
}
function useAuth(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  return currentUser;
}
function useAdminAuth(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkUserIsAdmin(currentUser)) {
      navigate("/restricted-route");
    }
  }, [currentUser, navigate]);

  return currentUser;
}
function useLoverAccount(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkUserLoverAccount(currentUser)) {
      navigate("/become-lover");
    }
  }, [currentUser, navigate]);

  return currentUser;
}

export const WithoutAuth = (props) => useNoAuth(props) && props.children;
export const WithAuth = (props) => useAuth(props) && props.children;
export const WithAdminAuth = (props) => useAdminAuth(props) && props.children;
export const WithLoverAccount = (props) =>
  useLoverAccount(props) && props.children;
