import React from "react";
import "./Page.scss";

export default function Page({ text, center, padding, children }) {
  return (
    <div
      className={`page${text === true ? " text" : ""}${
        center === "center" ? " center" : ""
      }${padding === true ? " padding" : ""}`}
    >
      {children}
    </div>
  );
}
