import messagesTypes from "./messages.types";

export const addMessageStart = (messageData) => ({
  type: messagesTypes.ADD_NEW_MESSAGE_START,
  payload: messageData,
});

export const fetchMessagesStart = (filters = {}) => ({
  type: messagesTypes.FETCH_MESSAGES_START,
  payload: filters,
});

export const setMessages = (messages) => ({
  type: messagesTypes.SET_MESSAGES,
  payload: messages,
});

export const deleteMessageStart = (messageID) => ({
  type: messagesTypes.DELETE_MESSAGE_START,
  payload: messageID,
});

export const fetchMessageStart = (messageID) => ({
  type: messagesTypes.FETCH_MESSAGE_START,
  payload: messageID,
});

export const setMessage = (message) => ({
  type: messagesTypes.SET_MESSAGE,
  payload: message,
});

export const updateMessageStart = (messageID, updatedData) => ({
  type: messagesTypes.UPDATE_MESSAGE_START,
  payload: { messageID, updatedData },
});
