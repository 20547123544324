import messagesTypes from "./messages.types";
import messageTypes from "./messages.types";

const INITIAL_STATE = {
  messages: [],
  message: {},
};

const messagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case messageTypes.SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case messagesTypes.SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case messagesTypes.UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.map(msg =>
          msg.documentID === action.payload.documentID
            ? { ...msg, ...action.payload.updatedData }
            : msg
        ),
      };
    default:
      return state;
  }
};

export default messagesReducer;
