const messagesTypes = {
  ADD_NEW_MESSAGE_START: "ADD_NEW_MESSAGE_START",
  FETCH_MESSAGES_START: "FETCH_MESSAGES_START",
  SET_MESSAGES: "SET_MESSAGES",
  DELETE_MESSAGE_START: "DELETE_MESSAGE_START",
  FETCH_MESSAGE_START: "FETCH_MESSAGE_START",
  SET_MESSAGE: "SET_MESSAGE",
  UPDATE_MESSAGE_START: "UPDATE_MESSAGE_START"
};

export default messagesTypes;
