import React, { useState } from "react";
import "./Buttons.scss";
import { useNavigate } from "react-router-dom";
import { HiOutlineExclamation } from "react-icons/hi";
import { ModalBottom } from "../Modal/Modals";
import { InputSelect } from "../Input/Input";

export function Button({
  title,
  hierarchy,
  name,
  disabled,
  flex,
  size,
  handleClick,
  margin,
  border,
  color,
  handleMouseOver,
  handleMouseLeave,
  type,
  back,
  hover,
  extendedClass,
}) {
  const navigate = useNavigate();

  const handleClickEvent = () => {
    if (back) {
      navigate(-1);
    } else if (handleClick) {
      handleClick();
    }
  };
  return (
    <button
      className={`${
        hierarchy === "primary"
          ? "btn btnPrimary"
          : hierarchy === "secondary"
          ? "btn btnSecondary"
          : hierarchy === "tertiary"
          ? "btn btnTertiary"
          : hierarchy === "link"
          ? "btn btnLink"
          : hierarchy === "menu"
          ? "btn btnMenu"
          : hierarchy === "nav"
          ? "btn btnNav"
          : hierarchy === "list"
          ? "btn btnList"
          : hierarchy === "favorite"
          ? "btn btnFavorite"
          : hierarchy === "close"
          ? "btn btnClose"
          : hierarchy === "card"
          ? "btn btnCard"
          : hierarchy === "select"
          ? "btn btnSelect"
          : hierarchy === "back"
          ? "btn btnBack"
          : hierarchy === "dots"
          ? "btn btnDots"
          : hierarchy
      }${
        flex === "left"
          ? " flex left"
          : flex === "right"
          ? " flex right"
          : flex === "leftJustify"
          ? " flex justify"
          : flex === "center"
          ? " flex center"
          : ""
      }${size === "small" ? " btnSmall" : ""}${
        color === "dark"
          ? " colorDark"
          : color === "transparent"
          ? " colorTransparent"
          : color === "green"
          ? " colorGreen"
          : color === "red"
          ? " colorRed"
          : color === "yellow"
          ? " colorYellow"
          : ""
      }${margin === "none" ? " marginNone" : ""}${
        border === "none" ? " borderNone" : ""
      }${back ? " btnBack" : ""}${hover ? " hoverTrue" : " hoverNone"}${
        extendedClass ? ` ${extendedClass}` : ""
      }`}
      onClick={handleClickEvent}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      disabled={disabled}
      type={type}
      name={name ? name : "Button name"}
    >
      {title}
    </button>
  );
}

export function ButtonReport({ user }) {
  //MODAL
  const [hideModalBottom, setHideModalBottom] = useState(true);
  const toggleModalBottom = () => setHideModalBottom(!hideModalBottom);
  const configModal = {
    hideModalBottom,
    toggleModalBottom,
  };
  return (
    <>
      <Button
        title={<HiOutlineExclamation />}
        hierarchy="favorite"
        type="button"
        handleClick={() => toggleModalBottom()}
      />
      <ModalBottom {...configModal}>
        <br />
        <h3>Report {user.displayName}</h3>
        <br />
        <InputSelect selectorTitle="Set reason" />
        <br />
        <Button title="Submit report" hierarchy="secondary" disabled />
        <br />
      </ModalBottom>
    </>
  );
}
