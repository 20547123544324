import React from "react";
import Navigation from "../Navigation/Navigation";
import Page from "../Page/Page";

export function LayoutPrimary({ children, pagePadding }) {
  return <Page padding={pagePadding}>{children}</Page>;
}

export function LayoutSecondary({
  title,
  children,
  btnBack,
  pagePadding,
  fullHeader,
  filters,
}) {
  return (
    <>
      <Navigation
        title={title}
        fullHeader={fullHeader}
        btnBack={btnBack ? btnBack : null}
        filters={filters}
      />
      <Page padding={pagePadding}>{children}</Page>
    </>
  );
}
