import React from 'react';

export const getDeviceType = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  if (/Windows|Macintosh|Linux/.test(userAgent)) {
    return 'PC';
  }

  return 'Unknown';
};

const DeviceDetector = () => {

  

  const deviceType = getDeviceType();

  return (
      <p className='texLeft'><small>Device: {deviceType}</small></p>
  );
};

export default DeviceDetector;
