import { auth, firestore } from "./../../database/utils";

//RESET PASSWORD
export const handleResetPasswordAPI = (email) => {
  const config = {
    url: "https://loversapp.co",
  };

  return new Promise((resolve, reject) => {
    auth
      .sendPasswordResetEmail(email, config)
      .then(() => {
        resolve();
      })
      .catch(() => {
        const err = ["Email not found. Please try again."];
        reject(err);
      });
  });
};
//SEND EMAIL VERIFICATION
export const handleEmailVerificationAPI = async () => {
  // try {
  //   await auth.currentUser.sendEmailVerification();
  // } catch (error) {
  //   // console.error("Error sending email verification:", error);
  //   throw error;
  // }
  const actionCodeSettings = {
    url: "https://loversapp.co/verify-email",
    handleCodeInApp: true,
  };

  const user = auth.currentUser;
  if (user) {
    return user
      .sendEmailVerification(actionCodeSettings)
      .then(() => {
        console.log("Verification email sent.");
      })
      .catch((error) => {
        console.error("Error sending verification email:", error);
      });
  }
};
//CONFIRM VERIFICATION AN UPDATE isVerified
export const handleUpdateIsVerified = async (userData) => {
  try {
    const { id } = userData;

    const userRef = firestore.collection("users").doc(id);
    await userRef.update({ isVerified: true });
    console.log("isVerified field updated in database for user:", id);
  } catch (error) {
    throw new Error("Error updating isVerified field in database");
  }
};
//GET USER BY EMAIL
export const getUserByEmail = async (email) => {
  const userRef = firestore
    .collection("users")
    .where("email", "==", email)
    .limit(1);
  const userSnapshot = await userRef.get();

  if (!userSnapshot.empty) {
    const userData = userSnapshot.docs[0].data();
    return { exists: true, ...userData };
  } else {
    return { exists: false };
  }
};
//UPDATE USER LOCATION
export const handleUpdateUserLocation = (userData) => {
  const { id, isOnline, lastSeen, lat, lon } = userData;

  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(id)
      .update({ isOnline, lastSeen, lat, lon })
      .then(() => resolve())
      .catch((err) => reject(err));
  });
};
//UPDATE USER PROFILE
export const handleUpdateUserProfile = (userData) => {
  const { id, isOnline, lastSeen, createdDate, ...updateData } = userData;

  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(id)
      .update({ isOnline, lastSeen, ...updateData })
      .then(() => resolve())
      .catch((err) => reject(err));
    console.log("user profile updated");
  });
};
//UPDATE BLOCK LIST
export const handleUpdateBlockList = (userID, targetID) => {
  return new Promise((resolve, reject) => {
    const userDocRef = firestore.collection("users").doc(userID);

    userDocRef
      .get()
      .then((userDoc) => {
        if (!userDoc.exists) {
          throw new Error("User document does not exist");
        }
        let blocked = userDoc.data().blocked || [];
        if (blocked.includes(targetID)) {
          blocked = blocked.filter((id) => id !== targetID);
        } else {
          blocked.push(targetID);
        }
        return userDocRef.update({ blocked: blocked }).then(() => blocked);
      })
      .then((updatedBlocked) => resolve(updatedBlocked))
      .catch((err) => reject(err));
  });
};
//SAVE FAVORITE USERS
export const handleSaveUser = async (userID, favUserID) => {
  const userDoc = firestore.collection("users").doc(userID);
  const doc = await userDoc.get();

  if (doc.exists) {
    const userData = doc.data();
    const currentFavoriteUsers = userData.favoriteUsers || [];
    const isUserAlreadySaved = currentFavoriteUsers.includes(favUserID);

    if (!isUserAlreadySaved) {
      const updatedFavoriteUsers = [...currentFavoriteUsers, favUserID];

      return userDoc.update({ favoriteUsers: updatedFavoriteUsers });
    }
  } else {
    console.error("User document does not exist");
  }
};
//REMOVE FAVORITE USER
export const handleRemoveUser = async (userID, favUserID) => {
  const userDoc = firestore.collection("users").doc(userID);
  const doc = await userDoc.get();

  if (doc.exists) {
    const userData = doc.data();
    const updatedSavedUsers = userData.favoriteUsers.filter(
      (savedUserID) => savedUserID !== favUserID
    );

    await userDoc.update({ favoriteUsers: updatedSavedUsers });
    return updatedSavedUsers;
  } else {
    console.error("User document does not exist");
    return [];
  }
};
