import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { updateUserLocationStart } from "../redux/user/user.actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const useLocationChange = (action) => {
  const location = useLocation();
  useEffect(() => {
    action(location);
  }, [location]);
};

//UPDATE LOCATION
export const Geolocator = ({ currentUser }) => {
  const dispatch = useDispatch();

  const updateLocation = () => {
    const success = (position) => {
      const { latitude, longitude } = position.coords;
      const updatedUserCoords = {
        ...currentUser,
        lat: latitude,
        lon: longitude,
      };
      dispatch(updateUserLocationStart(updatedUserCoords));
      // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    };

    const error = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.warn("Geolocation is not supported by this browser.");
    }
  };
  useLocationChange(() => {
    updateLocation();
  });
  return null;
};

const getDrivingDistance = async (lat1, lon1, lat2, lon2) => {
  const apiEndpoint = `https://us-central1-loversapps.cloudfunctions.net/api/get-distance?lat1=${lat1}&lon1=${lon1}&lat2=${lat2}&lon2=${lon2}`;

  try {
    const response = await fetch(apiEndpoint);
    const data = await response.json();

    if (data.status === "OK") {
      const distanceText = data.distance;
      return distanceText;
    } else {
      console.error("Error fetching distance data:", data.error_message);
      return "Unknown";
    }
  } catch (error) {
    console.error("Error fetching distance data:", error);
    return "Unknown";
  }
};

export const GeoMatch = ({ driverLat, driverLon }) => {
  const { t } = useTranslation();
  const [clientLocation, setClientLocation] = useState(null);
  const [distance, setDistance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setClientLocation({
          lat: position.coords.latitude.toFixed(7),
          lon: position.coords.longitude.toFixed(7),
        });
      },
      (error) => {
        console.error("Error:", error);
        setError("Unable to retrieve your location");
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    if (clientLocation && driverLat && driverLon) {
      getDrivingDistance(
        clientLocation.lat,
        clientLocation.lon,
        driverLat,
        driverLon
      )
        .then((result) => {
          setDistance(result);
          setLoading(false);
        })
        .catch((err) => {
          setError("Error calculating distance");
          setLoading(false);
        });
    }
  }, [clientLocation, driverLat, driverLon]);

  if (loading) {
    return <>{t("Loading")}...</>;
  }

  if (error) {
    return <>{error}</>;
  }

  return <>{distance ? <>{distance}</> : <>Distance not available</>}</>;
};
