import React from 'react';
import "./Header.scss";

export default function Header({title,strong, fullWidth}) {
  return (
    <h4 className={`headerFlex${fullWidth? " fullWidth":""}`}>
      {strong === true ? <strong>{title}</strong> : title}
    </h4>
  )
}
