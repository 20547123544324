import { auth } from "../../database/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import { setPlans, setPlan, fetchPlansStart } from "./plans.actions";
import {
  handleAddPlan,
  handleFetchPlans,
  handleFetchPlan,
  handleDeletePlan,
} from "./plans.helpers";
import plansTypes from "./plans.types";

export function* addPlan({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddPlan({
      ...payload,
      planAdminUserUID: auth.currentUser.uid,
      createdDate: timestamp,
    });
    yield put(fetchPlansStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* onAddPlanStart() {
  yield takeLatest(plansTypes.ADD_NEW_PLAN_START, addPlan);
}

export function* fetchPlans({ payload }) {
  try {
    const plans = yield handleFetchPlans(payload);
    yield put(setPlans(plans));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchPlansStart() {
  yield takeLatest(plansTypes.FETCH_PLANS_START, fetchPlans);
}

export function* deletePlan({ payload }) {
  try {
    yield handleDeletePlan(payload);
    yield put(fetchPlansStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* onDeletePlanStart() {
  yield takeLatest(plansTypes.DELETE_PLAN_START, deletePlan);
}

export function* fetchPlan({ payload }) {
  try {
    const plan = yield handleFetchPlan(payload);
    yield put(setPlan(plan));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchPlanStart() {
  yield takeLatest(plansTypes.FETCH_PLAN_START, fetchPlan);
}

export default function* plansSagas() {
  yield all([
    call(onAddPlanStart),
    call(onFetchPlansStart),
    call(onDeletePlanStart),
    call(onFetchPlanStart),
  ]);
}
