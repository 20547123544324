import React, { useRef } from "react";
import { HiOutlineX } from "react-icons/hi";
import { Button } from "../Button/Buttons";
import { FlexBox } from "../FlexBox/FlexBox";
import "./Modals.scss";

export function Modal({
  children,
  hideModal,
  toggleModal,
  extendedClass,
  index,
}) {
  const modalRef = useRef(null);
  const startY = useRef(null);
  const threshold = 100;

  const handleTouchStart = (event) => {
    startY.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    if (!modalRef.current || !startY.current) return;

    const deltaY = event.touches[0].clientY - startY.current;
    if (deltaY > threshold) {
      toggleModal();
    }
  };

  return (
    <React.Fragment key={index}>
      <div
        className={`backdropBottom ${hideModal ? "hidden" : ""}`}
        onClick={() => toggleModal()}
        ref={modalRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      />
      <div
        ref={modalRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        className={`modal ${hideModal ? "" : "show"}${
          extendedClass ? ` ${extendedClass}` : ""
        }`}
      >
        <div>{children}</div>
      </div>
      <div
        className={`backdrop${hideModal ? " hidden" : ""}`}
        onClick={() => toggleModal()}
      />
    </React.Fragment>
  );
}
export function ModalAge({ children, hideModalAge, index }) {
  return (
    <React.Fragment key={index}>
      <div className={`modal age ${hideModalAge ? "" : "show"}`}>
        {children}
      </div>
    </React.Fragment>
  );
}
export function ModalBottom({
  children,
  hideModalBottom,
  toggleModalBottom,
  index,
}) {
  const modalRef = useRef(null);
  const startY = useRef(null);
  const threshold = 100;

  const handleTouchStart = (event) => {
    startY.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    if (!modalRef.current || !startY.current) return;

    const deltaY = event.touches[0].clientY - startY.current;
    if (deltaY > threshold) {
      toggleModalBottom();
    }
  };

  return (
    <React.Fragment key={index}>
      <div
        className={`backdropBottom ${hideModalBottom ? "hidden" : ""}`}
        onClick={() => toggleModalBottom()}
        ref={modalRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      />
      <div
        ref={modalRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        className={`modalBottom ${hideModalBottom ? "" : "show"}`}
      >
        <span
          className="modalBottomCloseButton"
          onClick={() => toggleModalBottom()}
        />
        <div>{children}</div>
      </div>
      <div
        className={`backdrop${hideModalBottom ? " hidden" : ""}`}
        onClick={() => toggleModalBottom()}
      />
    </React.Fragment>
  );
}
export function ModalSmall({
  children,
  hideModalSmall,
  toggleModalSmall,
  index,
}) {
  return (
    <React.Fragment key={index}>
      <div
        className={`backdrop ${hideModalSmall ? "hidden" : ""}`}
        onClick={() => toggleModalSmall()}
      />
      <div className={`modalCenter ${hideModalSmall ? "" : "show"}`}>
        <Button
          title={<HiOutlineX />}
          className="btnClose"
          hierarchy="close"
          handleClick={() => toggleModalSmall()}
          type="button"
        />
        {children}
      </div>
    </React.Fragment>
  );
}
export function ModalPayment({
  children,
  hideModalPayment,
  toggleModalPayment,
  index,
}) {
  return (
    <React.Fragment key={index}>
      <div className={`modalPayment ${hideModalPayment ? "" : "show"}`}>
        <FlexBox>
          {children}
          <Button
            title={<HiOutlineX />}
            hierarchy="close"
            handleClick={() => toggleModalPayment()}
            type="button"
          />
        </FlexBox>
      </div>
    </React.Fragment>
  );
}
export function ModalUpdate({
  children,
  hideModalUpdate,
  toggleModalUpdate,
  index,
}) {
  return (
    <React.Fragment key={index}>
      <div className={`modalPayment ${hideModalUpdate ? "" : "show"}`}>
        <FlexBox>
          {children}
          <Button
            title={<HiOutlineX />}
            hierarchy="close"
            handleClick={() => toggleModalUpdate()}
            type="button"
          />
        </FlexBox>
      </div>
    </React.Fragment>
  );
}
