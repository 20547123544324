import { all, call } from "redux-saga/effects";

import userSagas from "./user/user.sagas";
import usersSagas from "./users/users.sagas";

import advicesSagas from "./advices/advices.sagas";
import messagesSagas from "./messages/messages.sagas";
import notificationsSagas from "./notifications/notifications.sagas";
import plansSagas from "./plans/plans.sagas";
import ordersSagas from "./orders/orders.sagas";

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(usersSagas),
    call(advicesSagas),
    call(messagesSagas),
    call(notificationsSagas),
    call(plansSagas),
    call(ordersSagas),
  ]);
}
