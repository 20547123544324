import { auth } from "../../database/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import { setMessages, setMessage, fetchMessagesStart } from "./messages.actions";
import {
  handleAddMessage,
  handleFetchMessages,
  handleFetchMessage,
  handleDeleteMessage,
  handleUpdateMessage,
} from "./messages.helpers";
import messagesTypes from "./messages.types";

export function* addMessage({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddMessage({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchMessagesStart());
  } catch (err) {}
}

export function* onAddMessageStart() {
  yield takeLatest(messagesTypes.ADD_NEW_MESSAGE_START, addMessage);
}

export function* fetchMessages({ payload }) {
  try {
    const messages = yield handleFetchMessages(payload);
    yield put(setMessages(messages));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchMessagesStart() {
  yield takeLatest(messagesTypes.FETCH_MESSAGES_START, fetchMessages);
}

export function* fetchMessage({ payload }) {
  try {
    const message = yield handleFetchMessage(payload);
    yield put(setMessage(message));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchMessageStart() {
  yield takeLatest(messagesTypes.FETCH_MESSAGE_START, fetchMessage);
}

export function* onDeleteMessageStart() {
  yield takeLatest(messagesTypes.DELETE_MESSAGE_START, deleteMessage);
}

export function* deleteMessage({ payload }) {
  try {
    yield handleDeleteMessage(payload);
    yield put(fetchMessagesStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* updateMessage({ payload: { messageID, updatedData } }) {
  try {
    yield handleUpdateMessage(messageID, updatedData);
    yield put(fetchMessagesStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* onUpdateMessageStart() {
  yield takeLatest(messagesTypes.UPDATE_MESSAGE_START, updateMessage);
}

export default function* messagesSagas() {
  yield all([
    call(onAddMessageStart),
    call(onFetchMessagesStart),
    call(onDeleteMessageStart),
    call(onFetchMessageStart),
    call(onUpdateMessageStart),
  ]);
}
