import React, { useEffect, useState } from "react";
import { getDeviceType } from "../DeviceDetector";
import { HiOutlineX, HiStar } from "react-icons/hi";

export default function GetAppBanner() {
  const deviceType = getDeviceType();

  //CHECK IF APP IS INSTALLED
  const [isAppInstalled, setIsAppInstalled] = useState(false);

  //HIDE OR SHOW BANNER
  const [isVisible, setIsVisible] = useState(true);
  const [canInstall, setCanInstall] = useState(false);

  const checkAppInstalled = async () => {
    if (deviceType === "iOS") {
      if (window.navigator && window.navigator.standalone) {
        return true;
      }
    } else if (deviceType === "Android") {
      if (navigator.getInstalledRelatedApps) {
        const relatedApps = await navigator.getInstalledRelatedApps();
        return relatedApps.some((app) => app.id === "com.yourapp.id");
      }
    } else if (deviceType === "PC") {
      return window.matchMedia("(display-mode: standalone)").matches;
    }
    return false;
  };

  useEffect(() => {
    const savedVisibility = localStorage.getItem("bannerVisible");
    if (savedVisibility === "false") {
      setIsVisible(false);
    }

    checkAppInstalled().then((installed) => {
      setIsAppInstalled(installed);
    });

    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      setCanInstall(true);
      window.deferredPrompt = event;
    });
  }, [deviceType]);

  //HIDE BANNER
  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem("bannerVisible", "false");
  };

  if (!isVisible) return null;

  //OPEN IF INSTALLED
  const handleAppOpenOrInstall = () => {
    if (isAppInstalled) {
      openApp();
    } else {
      installApp();
    }
  };

  const installApp = () => {
    if (canInstall && window.deferredPrompt) {
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          setIsAppInstalled(true);
        }
        window.deferredPrompt = null;
      });
    } else {
      window.location.href = downloadLink;
    }
  };

  //APP LINKS
  const storeLink = {
    PC: "https://www.microsoft.com/store/apps/yourapp",
    Android: "https://play.google.com/store/apps/details?id=com.yourapp.id",
    iOS: "https://apps.apple.com/app/yourapp-id",
    Unknown: "#",
  }[deviceType];

  //OPEN APP
  const openApp = () => {
    const appLink = {
      PC: "yourapp://",
      Android: "intent://#Intent;scheme=yourapp;package=com.yourapp.id;end",
      iOS: "yourapp://",
      Unknown: "#",
    }[deviceType];

    window.location.href = appLink;
  };

  const downloadLink = "/app-release-signed.apk";

  return (
    <div className="getAppBanner">
      <span>
        <button onClick={handleClose} className="btnHide">
          <HiOutlineX />
        </button>
        <img src="/assets/logos/logo192.png" alt="Lovers logo" />
        <p>
          Lovers App
          <br />
          <small className="flexBox">
            <HiStar color="#fbbc04" />
            <HiStar color="#fbbc04" />
            <HiStar color="#fbbc04" />
            <HiStar color="#fbbc04" />
            <HiStar color="#fbbc04" />
          </small>
          <small>
            {isAppInstalled
              ? "INSTALLED"
              : `FREE on ${
                  deviceType === "PC"
                    ? "Microsoft"
                    : deviceType === "Android"
                    ? "Google Play"
                    : deviceType === "iOS"
                    ? "App Store"
                    : "Unknown"
                }`}
          </small>
        </p>
      </span>
      <span>
        <a
          href={isAppInstalled ? "#" : storeLink}
          onClick={(e) => {
            e.preventDefault();
            handleAppOpenOrInstall();
          }}
          download="app-release-signed.apk"
        >
          <button className="btnGetApp">
            {!isAppInstalled ? "View" : "Open"}
          </button>
        </a>
      </span>
    </div>
  );
}
