import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
//STATE
import { updateUserProfileStart } from "../../redux/user/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
//COMPONENTS
import { Button } from "../Button/Buttons";
import { ModalBottom } from "../Modal/Modals";
import Header from "../Header/Header";
import { FlexBox } from "../FlexBox/FlexBox";
import { Radio } from "../Input/Input";
import GetAppBanner from "../GetAppBanner";
//STYLES
import "./Navigation.scss";
//ASSETS
import {
  HiHeart,
  HiOutlineArrowRight,
  HiOutlineChevronLeft,
  HiOutlineHeart,
  HiOutlineStar,
  HiStar,
  HiOutlineBell,
  HiBell,
  HiOutlineChat,
  HiChat,
} from "react-icons/hi";
import { LuListFilter } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import {
  MessagesBullet,
  NotificationsBullet,
} from "../Indicators/IndicatorsBullet";

const currentUserSelector = (state) => state.user.currentUser;
const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

export default function Navigation({ title, btnBack, fullHeader, filters }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(mapState);
  const { t } = useTranslation();
  //MODAL
  const [hideModalBottom, setHideModalBottom] = useState(true);
  const toggleModalBottom = () => setHideModalBottom(!hideModalBottom);

  const configModal = {
    hideModalBottom,
    toggleModalBottom,
  };
  //UPDATE CURRENT USER RESULTS
  const defaultResultsFilter = {
    valueGender: "",
    valueBodyType: "",
    valueSexualOrientation: "",
    valueMinAge: 18,
    valueMaxAge: 99,
    valueWeight: "",
    valueHeight: "",
    valueDistance: 1,
  };
  //NEW VALUES
  const resultsFilter = currentUser?.resultsFilter ?? defaultResultsFilter;

  const [newValueGender, setNewValueGender] = useState(
    resultsFilter.valueGender
  );
  const [newValueBodyType, setNewValueBodyType] = useState(
    resultsFilter.valueBodyType
  );
  const [newValueEthnicity, setNewValueEthnicity] = useState(
    resultsFilter.valueEthnicity
  );
  const [newValueSexualOrientation, setNewValueSexualOrientation] = useState(
    resultsFilter.valueSexualOrientation
  );

  const [newValueMinAge, setNewValueMinAge] = useState(
    resultsFilter.valueMinAge
  );
  const [newValueMaxAge, setNewValueMaxAge] = useState(
    resultsFilter.valueMaxAge
  );
  const [newValueWeight, setNewValueWeight] = useState(
    resultsFilter.valueWeight
  );
  const [newValueHeight, setNewValueHeight] = useState(
    resultsFilter.valueHeight
  );
  const [newValueDistance, setNewValueDistance] = useState(
    resultsFilter.valueDistance
  );

  const handleSaveChanges = (e) => {
    e.preventDefault();

    const updatedUser = {
      ...currentUser,
      resultsFilter: {
        valueGender: newValueGender,
        valueSexualOrientation: newValueSexualOrientation,
        valueMinAge: newValueMinAge,
        valueMaxAge: newValueMaxAge,
        valueWeight: newValueWeight,
        valueHeight: newValueHeight,
        valueDistance: newValueDistance,
      },
    };
    dispatch(updateUserProfileStart(updatedUser));
    toggleModalBottom();
  };

  const minRef = useRef(null);
  const maxRef = useRef(null);
  const rangeRef = useRef(null);

  useEffect(() => {
    const handleThumbDrag = (e, thumbType) => {
      const rangeWidth = rangeRef.current.offsetWidth;
      const rect = rangeRef.current.getBoundingClientRect();
      const offsetX = e.type.includes("touch")
        ? e.touches[0].clientX - rect.left
        : e.clientX - rect.left;
      const value = Math.round((offsetX / rangeWidth) * (99 - 18) + 18);

      if (thumbType === "min" && value < newValueMaxAge && value >= 18) {
        setNewValueMinAge(value);
      } else if (thumbType === "max" && value > newValueMinAge && value <= 99) {
        setNewValueMaxAge(value);
      }
    };

    const handleMouseMove = (e) => {
      if (minRef.current.dragging) {
        handleThumbDrag(e, "min");
      } else if (maxRef.current.dragging) {
        handleThumbDrag(e, "max");
      }
    };

    const handleMouseUp = () => {
      minRef.current.dragging = false;
      maxRef.current.dragging = false;
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
    };

    const handleMouseDown = (thumbType) => {
      return () => {
        if (thumbType === "min") {
          minRef.current.dragging = true;
        } else if (thumbType === "max") {
          maxRef.current.dragging = true;
        }
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("touchmove", handleMouseMove);
        document.addEventListener("touchend", handleMouseUp);
      };
    };

    minRef.current.addEventListener("mousedown", handleMouseDown("min"));
    maxRef.current.addEventListener("mousedown", handleMouseDown("max"));
    minRef.current.addEventListener("touchstart", handleMouseDown("min"));
    maxRef.current.addEventListener("touchstart", handleMouseDown("max"));

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [newValueMaxAge, newValueMinAge]);

  return (
    <>
      {title && (
        <>
          <nav className="navTop">
            {btnBack ? (
              <Button
                title={<HiOutlineChevronLeft />}
                hierarchy="back"
                back={true}
              />
            ) : null}
            <span className={fullHeader ? "fullHeader" : ""}>
              {title}
              {filters && (
                <LuListFilter
                  className="btnFilters"
                  onClick={() => toggleModalBottom()}
                />
              )}
            </span>
          </nav>
        </>
      )}
      {currentUser && (
        <>
          <nav>
            {currentUser.isVerified === false && (
              <div className="bannerTop">
                <p>
                  Email not verified <HiOutlineArrowRight />{" "}
                  <a
                    href="https://gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Verify now
                  </a>
                </p>
              </div>
            )}
            <div className="navWrapper">
              <NavLink to="/" activeclassname="active">
                {({ isActive }) => (
                  <Button
                    title={<>{!isActive ? <HiOutlineHeart /> : <HiHeart />}</>}
                    hierarchy="nav"
                  />
                )}
              </NavLink>
              <NavLink to="/favorites" activeclassname="active">
                {({ isActive }) => (
                  <Button
                    title={<>{!isActive ? <HiOutlineStar /> : <HiStar />}</>}
                    hierarchy="nav"
                  />
                )}
              </NavLink>
              <NavLink to="/messages" activeclassname="active">
                {({ isActive }) => (
                  <Button
                    title={
                      <>
                        <MessagesBullet extendedClass="navigation" />
                        {!isActive ? <HiOutlineChat /> : <HiChat />}
                      </>
                    }
                    hierarchy="nav"
                  />
                )}
              </NavLink>
              <NavLink to="/notifications" activeclassname="active">
                {({ isActive }) => (
                  <Button
                    title={
                      <>
                        <NotificationsBullet extendedClass="navigation" />
                        {!isActive ? <HiOutlineBell /> : <HiBell />}
                      </>
                    }
                    hierarchy="nav"
                  />
                )}
              </NavLink>
              <NavLink to="/account" activeclassname="active">
                <Button
                  title={
                    <img
                      src={currentUser.photoURL}
                      alt={currentUser.displayName}
                      className="navProfileImage"
                    />
                  }
                  hierarchy="nav"
                />
              </NavLink>
            </div>
          </nav>
        </>
      )}
      <GetAppBanner />
      <ModalBottom {...configModal}>
        <br />
        <h3>{t("Filters")}</h3>
        <br />
        <form onSubmit={handleSaveChanges}>
          <Header title={t("Gender")} strong={true} />
          <FlexBox scroll={true} fullWidth={true} extraClass="box">
            <Radio
              label={t("Male")}
              type="radio"
              name="gender"
              id="genderMaleFilter"
              row={true}
              checked={newValueGender === "Male"}
              handleChange={() => setNewValueGender("Male")}
            />
            <Radio
              label={t("Female")}
              type="radio"
              name="gender"
              id="genderFemaleFilter"
              row={true}
              checked={newValueGender === "Female"}
              handleChange={() => setNewValueGender("Female")}
            />
            <Radio
              label="Trans"
              type="radio"
              name="gender"
              id="genderTransFilter"
              row={true}
              checked={newValueGender === "Trans"}
              handleChange={() => setNewValueGender("Trans")}
            />
          </FlexBox>
          <br />
          <Header title={t("Body type")} strong={true} />
          <FlexBox scroll={true} fullWidth={true}>
            <Radio
              label="Fit"
              type="radio"
              name="bodyType"
              id="bodyTypeFitFilter"
              row={true}
              checked={newValueBodyType === "Fit"}
              handleChange={() => setNewValueBodyType("Fit")}
            />
            <Radio
              label="Slim"
              type="radio"
              name="bodyType"
              id="bodyTypeSlimFilter"
              row={true}
              checked={newValueBodyType === "Slim"}
              handleChange={() => setNewValueBodyType("Slim")}
            />
            <Radio
              label="Curvy"
              type="radio"
              name="bodyType"
              id="bodyTypeCurvyFilter"
              row={true}
              checked={newValueBodyType === "Curvy"}
              handleChange={() => setNewValueBodyType("Curvy")}
            />
          </FlexBox>
          <br />
          <Header title={t("Ethnicity")} strong={true} />
          <FlexBox scroll={true} fullWidth={true}>
            <Radio
              label="Caucasian"
              type="radio"
              name="ethnicity"
              id="ethnicityCaucasianFilter"
              row={true}
              checked={newValueEthnicity === "Caucasian"}
              handleChange={() => setNewValueEthnicity("Caucasian")}
            />
            <Radio
              label="Latino"
              type="radio"
              name="ethnicity"
              id="ethnicityLatinoFilter"
              row={true}
              checked={newValueEthnicity === "Latino"}
              handleChange={() => setNewValueEthnicity("Latino")}
            />
            <Radio
              label="Asian"
              type="radio"
              name="ethnicity"
              id="ethnicityAsianFilter"
              row={true}
              checked={newValueEthnicity === "Asian"}
              handleChange={() => setNewValueEthnicity("Asian")}
            />
            <Radio
              label="African"
              type="radio"
              name="ethnicity"
              id="ethnicityAfricanFilter"
              row={true}
              checked={newValueEthnicity === "African"}
              handleChange={() => setNewValueEthnicity("African")}
            />
            <Radio
              label="Mediterranean"
              type="radio"
              name="ethnicity"
              id="ethnicityMediterraneanFilter"
              row={true}
              checked={newValueEthnicity === "Mediterranean"}
              handleChange={() => setNewValueEthnicity("Mediterranean")}
            />
            <Radio
              label="Mixed Race"
              type="radio"
              name="ethnicity"
              id="ethnicityMixedRaceFilter"
              row={true}
              checked={newValueEthnicity === "Mixed race"}
              handleChange={() => setNewValueEthnicity("Mixed race")}
            />
          </FlexBox>
          <br />
          <Header title={t("Sexual orientation")} strong={true} />
          <FlexBox scroll={true} fullWidth={true}>
            <Radio
              label="Straight"
              type="radio"
              name="sexualOrientation"
              id="sexualOrientationStraightFilter"
              row={true}
              checked={newValueSexualOrientation === "Straight"}
              handleChange={() => setNewValueSexualOrientation("Straight")}
            />
            <Radio
              label="Gay"
              type="radio"
              name="sexualOrientation"
              id="sexualOrientationGayFilter"
              row={true}
              checked={newValueSexualOrientation === "Gay"}
              handleChange={(e) => setNewValueSexualOrientation("Gay")}
            />
            <Radio
              label="Lesbian"
              type="radio"
              name="sexualOrientation"
              id="sexualOrientationLesbianFilter"
              row={true}
              checked={newValueSexualOrientation === "Lesbian"}
              handleChange={() => setNewValueSexualOrientation("Lesbian")}
            />
            <Radio
              label="Trans"
              type="radio"
              name="sexualOrientation"
              id="sexualOrientationTransFilter"
              row={true}
              checked={newValueSexualOrientation === "Trans"}
              handleChange={() => setNewValueSexualOrientation("Trans")}
            />
          </FlexBox>
          <br />
          <Header title={t("Age")} fullWidth={true} strong={true} />
          <br />
          <p className="textLeft">
            {t("Between")} {newValueMinAge} {t("and")} {newValueMaxAge}
          </p>
          <br />
          <div className="rangeContainer">
            <div className="slider" ref={rangeRef}>
              <div className="track"></div>
              <div
                className="range"
                style={{
                  left: `${(newValueMinAge - 18) / 0.81 + 1.5}%`,
                  width: `${(newValueMaxAge - newValueMinAge) / 0.81 - 3}%`,
                }}
              ></div>
              <div
                ref={minRef}
                className="thumbIndicator"
                style={{ left: `${(newValueMinAge - 18) / 0.81}%` }}
              />
              <div
                ref={maxRef}
                className="thumbIndicator"
                style={{ left: `${(newValueMaxAge - 18) / 0.81}%` }}
              />
            </div>
          </div>
          <br />
          <br />
          <Header
            title={
              <>
                {t("Weight")} <span>{newValueWeight}kg</span>
              </>
            }
            fullWidth={true}
            strong={true}
          />
          <input
            type="range"
            min="35"
            max="120"
            value={newValueWeight}
            onChange={(e) => setNewValueWeight(e.target.value)}
          />
          <br />
          <br />
          <Header
            title={
              <>
                {t("Height")} <span>{newValueHeight}cm</span>
              </>
            }
            fullWidth={true}
            strong={true}
          />
          <input
            type="range"
            min="100"
            max="220"
            value={newValueHeight}
            onChange={(e) => setNewValueHeight(e.target.value)}
          />
          <br />
          <br />
          <Header
            title={
              <>
                {t("Distance")} <span>{newValueDistance}km</span>
              </>
            }
            fullWidth={true}
            strong={true}
          />
          <input
            type="range"
            min="1"
            max="999"
            value={newValueDistance}
            onChange={(e) => setNewValueDistance(e.target.value)}
          />
          <br />
          <br />
          <Button
            title={t("Apply filters")}
            type="submit"
            hierarchy="secondary"
          />
          <Button
            title={t("Cancel")}
            type="button"
            hierarchy="tertiary"
            handleClick={() => toggleModalBottom()}
          />
        </form>
      </ModalBottom>
    </>
  );
}
