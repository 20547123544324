import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import usersReducer from "./users/users.reducer";
import advicesReducer from "./advices/advices.reducer";
import messagesReducer from "./messages/messages.reducer";
import notificationsReducer from "./notifications/notifications.reducer";
import plansReducer from "./plans/plans.reducer";
import ordersReducer from "./orders/orders.reducer";

export const rootReducer = combineReducers({
  user: userReducer,
  usersData: usersReducer,
  advicesData: advicesReducer,
  messagesData: messagesReducer,
  notificationsData: notificationsReducer,
  plansData: plansReducer,
  ordersData: ordersReducer,
});

const configStorage = {
  key: "root",
  storage,
  whitelist: ["cartData"],
};

export default persistReducer(configStorage, rootReducer);
